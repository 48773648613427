import { NgModule } from '@angular/core';
import { FormatThousandsPipe } from './format-thousands/format-thousands.pipe';
import { FormatRutPipe } from './format-rut/format-rut.pipe';
import { RoundDecimalPipe } from './round-decimal/round-decimal.pipe';

@NgModule({
  declarations: [FormatThousandsPipe, FormatRutPipe, RoundDecimalPipe],
  imports: [],
  exports: [FormatThousandsPipe, FormatRutPipe, RoundDecimalPipe],
})
export class PipesModule {}
