import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'RoundDecimal',
})
export class RoundDecimalPipe implements PipeTransform {
  transform(number: number, exp: number = 0): number {
    if (number) {
      if (typeof exp === 'undefined' || +exp === 0) {
        return Math.round(number);
      }
      number = +number;
    
      exp = +exp;

    if (isNaN(number) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }

    let numberParse: any = number.toString().split('e');
    numberParse = Math.round(+(numberParse[0] + 'e' + (numberParse[1] ? (+numberParse[1] - exp) : -exp)));

    numberParse = numberParse.toString().split('e');
    return +(numberParse[0] + 'e' + (numberParse[1] ? (+numberParse[1] + exp) : exp));

    } else {
      return 0;
    }
  }
}
